import * as React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import { getFormattedDateString } from "../components/helpers";

const Template = ({data}) => {
    let post = data.mdx;
    let datestr = getFormattedDateString(new Date(post.frontmatter.publishdate));

    return (
        <Layout>
        <article className="blog-post">
            <header>
                <h1>{post.frontmatter.title}</h1>
            </header>
            <section>
                <MDXRenderer>{post.body}</MDXRenderer>
            </section>
            <footer>
                <div class="meta-item publish-date">
                    <span class="field-name">Published On: </span>
                    <span class="field-value">{datestr}</span>
                </div>
            </footer>
        </article>
        </Layout>
    );
}

export default Template;

export const pageQuery = graphql`
query BlogPostById($id: String!) {
  mdx(id: {eq: $id}) {
    frontmatter {
        title
        publishdate
    }
    body
    slug
  }
}
`;